import React from "react"
import NextLink from "next/link"
import Grid from "../../common/components/Grid"
import Cell from "../../common/components/Cell"
import Card from "../../common/components/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChartBar,
  faMapMarkedAlt,
  faUserPlus,
  faBuilding,
} from "@fortawesome/pro-light-svg-icons"
import {
  Box,
  Divider,
  Flex,
  Heading,
  Img,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useTheme,
} from "@chakra-ui/react"
import AppDownloadButtons from "../../common/components/AppDownloadButtons"
import useColors from "../../common/hooks/useColors"

const EmptyFeedImage = "/images/empty-feed.jpg"

const EmptyFeed = ({ loggedInUser }) => {
  const { whiteColor } = useColors()
  return (
    <Box mt={5}>
      <Card
        variant="input"
        overflow="hidden"
        display="flex"
        alignItems="stretch"
        flexDirection="column"
      >
        <Img
          objectFit="cover"
          objectPosition={"0% 0%"}
          maxWidth="none"
          m={-5}
          mb={4}
          alt="onboarding"
          src={EmptyFeedImage}
        />
        <Heading mt={3} size="lg" as="h1">
          What is Pebble?
        </Heading>
        <Text>Pebble’s goal is to make you a better climber.</Text>
        <Text>
          <UnorderedList spacing={2}>
            <ListItem>
              By tracking both your indoor and outdoor climbing as well as any
              climbing specific workouts, Pebble can begin to understand your
              current climbing ability.
            </ListItem>
            <ListItem>
              Once you log your sends and complete workouts, Pebble will begin
              recommending certain climbs to you. You can get recommendations
              based on a specific gym, guide or even location.
            </ListItem>
            <ListItem>
              The more everyone adds to Pebble, the smarter it will become. Soon
              it will be able to recommend climbs based on your favorite
              climbing style. Or, if you want to get better at your weaknesses,
              Pebble will make recommendations for getting stronger at those as
              well.
            </ListItem>
          </UnorderedList>
        </Text>
        <Divider />
        <Grid my={5}>
          <Cell col={2} className="vert-align">
            <Flex
              margin="11px 15px"
              borderRadius="100%"
              background={"theme.headingColor"}
              width="50px"
              height="50px"
              fontSize="24px"
              color="#fff"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon color={whiteColor} icon={faMapMarkedAlt} />
            </Flex>
          </Cell>
          <Cell col={10}>
            <Heading size="md" as="h4">
              Find a climb
            </Heading>
            <Text>
              Use our{" "}
              <NextLink passHref href="/map">
                <Link>guides</Link>
              </NextLink>{" "}
              to find something to climb. If you can’t find what your looking
              for, give back a little by adding a{" "}
              <NextLink passHref href="/create/route">
                <Link>route</Link>
              </NextLink>{" "}
              or{" "}
              <NextLink passHref href="/create/problem">
                <Link>boulder</Link>
              </NextLink>{" "}
              to the site.
            </Text>
          </Cell>
        </Grid>
        <Grid my={5}>
          <Cell col={2} className="vert-align">
            <Flex
              margin="11px 15px"
              borderRadius="100%"
              background={"theme.headingColor"}
              width="50px"
              height="50px"
              fontSize="24px"
              color="#fff"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon color={whiteColor} icon={faBuilding} />
            </Flex>
          </Cell>
          <Cell col={10}>
            <Heading size="md" as="h4">
              Train at your gym
            </Heading>
            <Text>
              Follow your{" "}
              <NextLink passHref href="/gyms">
                <Link>gym</Link>
              </NextLink>{" "}
              and find out when new climbs are set. Track your sends and log
              your training inside and soon pebble will be recommending your
              next tick list outside.
            </Text>
          </Cell>
        </Grid>
        <Grid my={5}>
          <Cell col={2} className="vert-align">
            <Flex
              margin="11px 15px"
              borderRadius="100%"
              background={"theme.headingColor"}
              width="50px"
              height="50px"
              fontSize="24px"
              color="#fff"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon color={whiteColor} icon={faUserPlus} />
            </Flex>
          </Cell>
          <Cell col={10}>
            <Heading size="md" as="h4">
              Follow your friends
            </Heading>
            <Text>
              Find motivation in what your friends are sending. Don’t have any
              friends that climb? No worries, follow the pros or your local
              crushers. Find them all in the{" "}
              <NextLink passHref href="/athletes">
                <Link>athletes</Link>
              </NextLink>{" "}
              section.
            </Text>
          </Cell>
        </Grid>
        <Grid my={5}>
          <Cell col={2} className="vert-align">
            <Flex
              margin="11px 15px"
              borderRadius="100%"
              background={"theme.headingColor"}
              width="50px"
              height="50px"
              fontSize="24px"
              color="#fff"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon color={whiteColor} icon={faChartBar} />
            </Flex>
          </Cell>
          <Cell col={10}>
            <Heading size="md" as="h4">
              Track your sends
            </Heading>
            <Text>
              Find and track your climbing by searching for boulders and routes
              in our{" "}
              <NextLink passHref href="/map">
                <Link>guides</Link>
              </NextLink>{" "}
              section or on your{" "}
              <NextLink passHref href="/gyms">
                <Link>gym’s</Link>
              </NextLink>{" "}
              page. By, logging your sends you can visualize your progress in
              your{" "}
              <NextLink passHref href={`/users/${loggedInUser.slug}`}>
                <Link>profile</Link>
              </NextLink>{" "}
              and Pebble will eventually start recommending new climbs to try.
            </Text>
          </Cell>
        </Grid>
      </Card>
      <Card>
        <Heading size="md" as="h4">
          Grab the App
        </Heading>
        <Text>
          Download the Pebble App to log your sends at your local gym or
          discover new climbing areas using the guides.{" "}
        </Text>
        <AppDownloadButtons />
      </Card>
    </Box>
  )
}

export default EmptyFeed
