import React from "react"
import { Box } from "@chakra-ui/react"

const AppDownloadButtons = ({ isCentered }) => {
  return (
    <Box
      position="relative"
      display="flex"
      zIndex={10}
      flexDirection="row"
      alignItems="center"
      justifyContent={isCentered ? "center" : "flex-start"}
    >
      <Box ml={[8, 0, 0]}>
        <a
          className="no-hover-underline"
          href="https://apps.apple.com/us/app/pebble-climbing/id1453943563"
        >
          <img
            height="36"
            alt="Get it on the App Store"
            src={
              "/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
            }
          />
        </a>
      </Box>
      <Box ml={3} textAlign="center" height={"60px"}>
        <a
          className="no-hover-underline"
          href="https://play.google.com/store/apps/details?id=com.pebbleclimbing.pebble&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            style={{
              height: 60,
            }}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </Box>
    </Box>
  )
}

export default AppDownloadButtons
