import { useQuery } from "@apollo/client"
import { FeedQuery, GET_FULL_FEED } from "../graphql/feed"

const Feed = ({ render, isAdmin }) => {
  const {
    data = {},
    fetchMore,
    loading,
    error,
    networkStatus,
    refetch,
  } = useQuery(isAdmin ? GET_FULL_FEED : FeedQuery, {
    variables: { cursor: null },
    fetchPolicy: "cache-and-network",
  })
  let { feed = {}, fullFeed = {} } = data
  if ((!feed && !fullFeed) || error) {
    return render({
      error,
    })
  }
  feed = feed.feedItems ? feed : fullFeed
  const { hasMore, feedItems, cursor } = feed
  return render({
    hasMore,
    feedItems,
    loading,
    error,
    status: networkStatus,
    refetchFeed: refetch,
    loadMoreItems: () => {
      fetchMore({
        variables: { cursor },
      })
    },
  })
}

export default Feed
