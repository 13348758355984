import _ from "lodash"
import React, { useState } from "react"
import NextLink from "next/link"
import {
  Box,
  LinkOverlay,
  Button,
  LinkBox,
  Heading,
  useTheme,
} from "@chakra-ui/react"
import Tooltip from "../../common/components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { setQueryParam, getQueryParam } from "../../utilities/queryParams"
import TextLink from "../../common/components/TextLink"
import UserListModal from "../../users/components/UserListModal"
import useColors from "../../common/hooks/useColors"

import styles from "./css/UserFeedCard.module.css"

const UserFeedCard = ({ loggedInUser, router }) => {
  const { whiteColor } = useColors()
  const [isFollowersOpen, setIsFollowersOpen] = useState(false)
  const [isFollowingOpen, setIsFollowingOpen] = useState(false)

  if (!loggedInUser) {
    return null
  }

  const { name, slug, profile } = loggedInUser
  const picture = _.get(profile, "picture")

  const isShowingFull = getQueryParam("isShowingFullFeed", router) === "true"

  return (
    <div className={styles["user-feed-card"]}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {picture ? (
          <div
            className={styles["user-img"]}
            style={{
              backgroundImage: "url(" + picture + ")",
            }}
          />
        ) : (
          <LinkBox>
            <div
              data-tip
              data-tooltip-id="edit-user-tt"
              className={styles["user-icon"] + " user-chip-img"}
            >
              <FontAwesomeIcon color={whiteColor} icon={faUser} />
            </div>
            <Tooltip class="card-tooltip" id="edit-user-tt">
              <NextLink passHref href={"/edit/user/" + loggedInUser.slug}>
                <LinkOverlay>
                  <h6>Edit Profile</h6>
                </LinkOverlay>
              </NextLink>
            </Tooltip>
          </LinkBox>
        )}
        <Heading mt={3} mb={4} variant="link" as="h3" size="md">
          <TextLink to={"/users/" + slug}>{name}</TextLink>
        </Heading>
        <div className={styles["stats"]}>
          <div className={styles["stat"]}>
            <Heading variant="link" as="h4" size="xl">
              <TextLink to={"/users/" + loggedInUser.slug}>
                {loggedInUser.totalAscents}
              </TextLink>
            </Heading>
            <Heading as="h6" size="xs" islight="true">
              Sends
            </Heading>
          </div>
          <div className={styles["stat"]}>
            <Heading
              variant="link"
              as="h4"
              size="xl"
              cursor="pointer"
              color="brand.blueColor"
              sx={{
                _hover: {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                setIsFollowingOpen(true)
              }}
            >
              {loggedInUser.following.length}
            </Heading>
            <Heading as="h6" size="xs" islight="true">
              Following
            </Heading>
          </div>
          <div className={styles["stat"]}>
            <Heading
              variant="link"
              as="h4"
              size="xl"
              cursor="pointer"
              color="brand.blueColor"
              sx={{
                _hover: {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                setIsFollowersOpen(true)
              }}
            >
              {loggedInUser.followers.length}
            </Heading>
            <Heading as="h6" size="xs" islight="true">
              Followers
            </Heading>
          </div>
        </div>
        {loggedInUser.isAdmin && (
          <Button
            variant="secondary"
            my={5}
            onClick={() => {
              setQueryParam(
                "isShowingFullFeed",
                isShowingFull ? "false" : "true",
                router,
              )
            }}
          >
            {isShowingFull ? "Hide" : "Show"} Full Stream
          </Button>
        )}
      </Box>
      <UserListModal
        isOpen={isFollowingOpen}
        title="You are following:"
        users={loggedInUser.following}
        onClose={() => {
          setIsFollowingOpen(false)
        }}
      />
      <UserListModal
        isOpen={isFollowersOpen}
        title="Your followers:"
        users={loggedInUser.followers}
        onClose={() => {
          setIsFollowersOpen(false)
        }}
      />
    </div>
  )
}

export default UserFeedCard
