import React, { useContext, useEffect } from "react"
import { withRouter } from "next/router"
import Page from "../../common/pages/Page"
import FeedCont from "../containers/FeedCont.js"
import Feed from "../components/Feed"
import { feedMeta } from "../../config/meta"
import { NavContext } from "../../context/NavContext"
import { getQueryParam } from "../../utilities/queryParams"

const FeedPage = (props) => {
  const { setSelectedNav } = useContext(NavContext)
  useEffect(() => {
    setSelectedNav("/feed")
  }, [setSelectedNav])

  const isShowingFullFeed =
    getQueryParam("isShowingFullFeed", props.router) === "true"

  return (
    <Page {...feedMeta()}>
      <FeedCont
        isAdmin={props?.loggedInUser?.isAdmin && isShowingFullFeed}
        render={({ hasMore, feedItems, loading, loadMoreItems }) => (
          <Feed
            {...props}
            hasMore={hasMore}
            feedItems={feedItems}
            loading={loading}
            loadMoreItems={loadMoreItems}
          />
        )}
      />
    </Page>
  )
}

export default withRouter(FeedPage)
