import _ from "lodash"
import queryString from "query-string"

export const setQueryParam = (key, value, router) => {
  const queryParams = queryString.parse(router.asPath?.split("?")?.[1] || "", {
    arrayFormat: "bracket",
  })
  if (
    value === queryParams[key] ||
    (!queryParams[key] && (!value || value.length === 0))
  ) {
    return
  }
  queryParams[key] = value
  router.replace(
    router.asPath?.split("?")[0] +
      "?" +
      queryString.stringify(queryParams, { arrayFormat: "bracket" }),
    undefined,
    { shallow: true },
  )
}

export const getQueryParam = (key, router) => {
  const queryParams = queryString.parse(router.asPath?.split("?")?.[1] || "", {
    arrayFormat: "bracket",
  })
  return queryParams[key]
}

export const clearQueries = (router, omitAr) => {
  router.replace(
    {
      pathname: router.asPath.split("?")[0],
      query: omitAr ? _.omit(router?.query || {}, omitAr) : {},
    },
    undefined,
    { shallow: true },
  )
}
